<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Data Jabatan</p>
            <v-spacer />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="getToPage('data-kelompok-jabatan')"
                >
                  <v-icon>settings</v-icon>
                </v-btn>
              </template>
              <span>Daftar Kelompok Jabatan</span>
            </v-tooltip>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2">
            <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12" class="px-0">
              <v-btn
                text
                class="text-capitalize caption headline-color"
                color="primary"
                @click="
                  visible = true;
                  form.jabatan_id = null;
                "
                >Buat Jabatan<v-icon class="ml-2" color="primary"
                  >add_circle</v-icon
                ></v-btn
              >
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12" class="px-0">
              <v-autocomplete
                placeholder="- Seluruh Kategori - "
                v-model="kategori"
                :items="kategoriList"
                :loading="loadingKategori"
                outlined
                dense
                hide-details
                clearable
                class="mx-2"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12" class="px-0">
              <v-autocomplete
                placeholder="- Seluruh Kelompok - "
                v-model="kelompok"
                :items="kelompokList"
                :loading="loadingKelompok"
                outlined
                dense
                hide-details
                clearable
                item-text="nama_kel_jabatan"
                item-value="kel_jabatan_id"
                class="mx-2"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12" class="px-0">
              <v-text-field
                v-model="search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
                class="mx-2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="lists"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon small color="primary" @click="handleEdit(item)"
                ><v-icon small>edit</v-icon></v-btn
              >
              <v-btn icon small color="primary" @click="handleDelete(item)"
                ><v-icon small>delete</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="650">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Buat Jabatan
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Jabatan</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                v-model="form.nama_jabatan"
                outlined
                dense
                :rules="[v => !!v || 'Jabatan harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Singkatan</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                v-model="form.singkatan"
                outlined
                dense
                :rules="[v => !!v || 'Singkatan harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Kategori</v-subheader
              >
            </v-col>
            <v-col cols="3" class="py-1">
              <v-autocomplete
                v-model="form.kategori"
                :items="kategoriList"
                :loading="loadingKategori"
                outlined
                dense
                :rules="[v => !!v || 'Kategori harus diisi']"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Rumpun</v-subheader
              >
            </v-col>
            <v-col cols="3" class="py-1">
              <v-autocomplete
                v-model="form.subkategori"
                :items="rumpunList"
                :loading="loadingRumpun"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Eselon Awal</v-subheader
              >
            </v-col>
            <v-col cols="3" class="py-1">
              <v-autocomplete
                v-model="form.eselon_id_awal"
                :items="eselonList"
                :loading="loadingEselon"
                outlined
                dense
                item-text="eselon"
                item-value="eselon_id"
                :rules="[v => !!v || 'Eselon Awal harus diisi']"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Eselon Akhir</v-subheader
              >
            </v-col>
            <v-col cols="3" class="py-1">
              <v-autocomplete
                v-model="form.eselon_id_akhir"
                :items="eselonList"
                :loading="loadingEselon"
                outlined
                dense
                item-text="eselon"
                item-value="eselon_id"
                :rules="[v => !!v || 'Eselon Akhir harus diisi']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Kelas Jabatan</v-subheader
              >
            </v-col>
            <v-col cols="3" class="py-1">
              <v-autocomplete
                v-model="form.kelas_jabatan"
                :items="kelasList"
                :loading="loadingKelas"
                outlined
                dense
                item-text="kelas_jabatan"
                item-value="kelas_jabatan"
                :rules="[v => !!v || 'Kelas Jabatan harus diisi']"
                @change="changeKelasJabatan"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nilai Jabatan</v-subheader
              >
            </v-col>
            <v-col cols="3" class="py-1">
              <v-text-field
                v-model="form.nilai_jabatan"
                outlined
                dense
                :rules="[v => !!v || 'Nilai Jabatan harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Kelompok Jabatan</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-autocomplete
                v-model="form.kel_jabatan_id"
                :items="kelompokList"
                :loading="loadingKelompok"
                outlined
                dense
                item-text="nama_kel_jabatan"
                item-value="kel_jabatan_id"
                :rules="[v => !!v || 'Kelompok Jabatan harus diisi']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DataUtamaService from "@/services/resources/data.utama.service";

export default {
  data() {
    return {
      visible: false,
      valid: false,
      isEdit: false,
      loading: false,
      formLoading: false,
      loadingKategori: false,
      loadingKelompok: false,
      loadingKelas: false,
      loadingRumpun: false,
      loadingEselon: false,
      rumpunList: ["Keahlian", "Keterampilan"],
      kategoriList: [
        "Struktural",
        "Pelaksana",
        "Fungsional",
        "Fungsional Umum",
        "Fungsional Tertentu"
      ],
      kelompokList: [],
      kelasList: [],
      eselonList: [],
      search: null,
      kategori: null,
      kelompok: null,
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          align: "center"
        },
        {
          text: "Kode Jabatan",
          value: "jabatan_id",
          sortable: false,
          align: "center"
        },
        {
          text: "Jabatan",
          value: "nama_jabatan",
          sortable: false,
          align: "center"
        },
        {
          text: "Kategori",
          value: "kategori",
          sortable: false,
          align: "center"
        },
        {
          text: "Kelompok",
          value: "nama_kel_jabatan",
          sortable: false,
          align: "center"
        },
        {
          text: "Kelas",
          value: "kelas_jabatan",
          sortable: false,
          align: "center"
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      lists: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["jabatan_id"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      form: {
        jabatan_id: null,
        nama_jabatan: null,
        singkatan: null,
        kategori: null,
        subkategori: null,
        eselon_id_awal: null,
        eselon_id_akhir: null,
        kelas_jabatan: null,
        nilai_jabatan: null,
        unit_utama_id: null,
        kel_jabatan_id: null
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getJabatanList);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getJabatanList);
    },
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    paginationProperty() {
      return [this.search, this.kategori, this.kelompok].join();
    }
  },
  methods: {
    handleEdit(item) {
      this.visible = true;
      this.getDetailJabatan(item.jabatan_id);
    },
    handleDelete(item) {
      this.$confirm({
        title: "Confirm",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            let payload = {
              jabatan_id: item.jabatan_id
            };
            this.deleteJabatan(payload);
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("action", this.form.jabatan_id ? 2 : 1);
        formData.append("jabatan_id", this.form.jabatan_id || 0);
        formData.append("nama_jabatan", this.form.nama_jabatan);
        formData.append("singkatan", this.form.singkatan);
        formData.append("kategori", this.form.kategori);
        formData.append("subkategori", this.form.subkategori);
        formData.append("eselon_id_awal", this.form.eselon_id_awal);
        formData.append("eselon_id_akhir", this.form.eselon_id_akhir);
        formData.append("kelas_jabatan", this.form.kelas_jabatan);
        formData.append("nilai_jabatan", this.form.nilai_jabatan);
        formData.append("kel_jabatan_id", this.form.kel_jabatan_id);
        this.saveJabatan(formData);
      }
    },
    changeKelasJabatan(item) {
      this.form.nilai_jabatan = this.kelasList.find(
        d => d.kelas_jabatan == item
      )?.nilai_jabatan;
    },
    // Service
    async getKelompokJabatanListAll() {
      try {
        this.loadingKelompok = true;
        await DataUtamaService.getKelompokJabatanListAll()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.kelompokList = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingKelompok = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getKelasJabatanListAll() {
      try {
        this.loadingKelas = true;
        await DataUtamaService.getKelasJabatanListAll()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.kelasList = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingKelas = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getEselonListAll() {
      try {
        this.loadingEselon = true;
        await DataUtamaService.getEselonListAll()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.eselonList = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingEselon = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getJabatanList() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await DataUtamaService.getJabatanList({
          filter: {
            search: this.search,
            kel_jabatan_id: this.kelompok,
            kategori: this.kategori
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            let { list, pageTotal, countTotal } = data;
            if (status) {
              let lists = list;
              lists.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.lists = lists;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveJabatan(data) {
      try {
        this.formLoading = true;
        await DataUtamaService.saveJabatan(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getJabatanList();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailJabatan(data) {
      try {
        this.loading = true;
        await DataUtamaService.getJabatanDetail(data)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.$_.merge(this.form, data);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteJabatan(payload) {
      try {
        this.formLoading = true;
        await DataUtamaService.deleteJabatan(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getJabatanList();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getKelompokJabatanListAll();
    this.getKelasJabatanListAll();
    this.getEselonListAll();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
